import { createSlice } from "@reduxjs/toolkit";


const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  // Parse stored json or if none return empty object
  const userData = item ? JSON.parse(item) : {};

  const userIsSuperAdmin = userData?.user_type?.toUpperCase() === "S";
  const userIsTeacher = userData?.user_type?.toUpperCase() === "T";
  const userIsParent = userData?.user_type?.toUpperCase() === "P";
  const userIsStudent = userData?.user_type?.toUpperCase() === "E";

  return { userData, userIsSuperAdmin, userIsTeacher, userIsParent, userIsStudent };
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    ...initialUser(),
  },
  reducers: {
    updateUserData: (state, action) => {

      // update state of store 
      state.userData.email = action.payload.email;
      state.userData.user_name = action.payload.user_name;
      state.userData.user_image = action.payload.user_image;

      // update localStorage
      const userData = JSON.parse(localStorage.getItem('userData'));

      userData.email = action.payload.email;
      userData.user_name = action.payload.user_name;
      userData.user_image = action.payload.user_image;

      localStorage.setItem("userData", JSON.stringify(userData));


    },
    handleLogin: (state, action) => {
      state.userData = action.payload;
      //store user data to persist 
      localStorage.setItem("userData", JSON.stringify(action.payload));

      state.userIsSuperAdmin = action.payload?.user_type.toUpperCase() == "S";
      state.userIsTeacher = action.payload?.user_type.toUpperCase() == "T";
      state.userIsParent = action.payload?.user_type.toUpperCase() == "P";
      state.userIsStudent = action.payload?.user_type.toUpperCase() == "E";
    },
    handleLogout: (state) => {
      state.userData = {};
      state.userIsSuperAdmin = false;
      state.userIsTeacher = false;
      state.userIsParent = false;
      state.userIsStudent = false;

      localStorage.clear()
  

    },
  },
});

export const { handleLogin, handleLogout, updateUserData } = authSlice.actions;

export default authSlice.reducer;