// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'navbar',
  initialState: {
    query:"",
    bookmarks: [],
    suggestions: []
  },
  reducers: {
     handleSearchQuery: (state, action) => {
      state.query = action.payload 
     },
     getBookmarks:(state,action)=>{
      state.suggestions=action.payload
      // set list of bookmarks by list of favoris in localStorge 
      const user_type=localStorage.getItem("user_type"); 
      const data=JSON.parse(localStorage.getItem(user_type))
        data ? state.bookmarks= data :state.bookmarks=[]
     },

     updateBookmarked:(state,action)=>{
      
      let objectToUpdate
      // ** find & update object
      state.suggestions.find(item => {
        if (item.id === action.payload) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }
     }
    },

})

export const {handleSearchQuery,getBookmarks,updateBookmarked} = layoutSlice.actions

export default layoutSlice.reducer
